import React,{ useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {
     editUser, deleteUser
  }  from "../actions/usersactions";
import {isLive} from '../config/keys';
import { dateStyle, dateStyleMoment } from '../config/dateStyle';
import {localization} from '../config/materialTable';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'table',
    width: '100%',
    textAlign: 'center',
    margin: 0
  },
  heading: {
    display: 'table-row'
  },
  headingCell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    padding: 8,
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  }
  ,
  line: {
    display: 'table-row'
  },
  cell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: 10
  },
  image: {
    width: 200
  }
}));

export default function Users() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  const dispatch = useDispatch();

  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  useEffect(()=>{
    if(usersdata.users){
      if(usersdata.users){
        setData(usersdata.users.filter(user => user.usertype ==='rider'));
      }else{
      setData([]);
      }
    }
  },[usersdata.users]);

  const [settings,setSettings] = useState({});
  
  const settingsdata = useSelector(state => state.settingsdata);

  useEffect(()=>{
    if(settingsdata.settings){
      setSettings(settingsdata.settings);
    }
  },[settingsdata.settings]);

  const columns = [
      { title: languageJson.createdAt, field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt?new Date(rowData.createdAt).toLocaleString(dateStyleMoment):null},
      { title: languageJson.first_name, field: 'firstName', editable:'never'},
      { title: languageJson.last_name, field: 'lastName', editable:'never'},
      { title: languageJson.user_type, field: 'usertype', editable:'never'},
      { title: languageJson.email, field: 'email', render: rowData => rowData.email.toLowerCase(), editable:'never'},
      { title: languageJson.mobile, field: 'mobile', editable:'never'},
      { title: languageJson.profile_image,  field: 'profile_image',render: rowData => rowData.profile_image?<img alt={languageJson.profile_image} src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never'},
      { title: languageJson.wallet_balance,  field: 'walletBalance',render: rowData => (rowData.walletBalance > 0) ? settings.symbol + ' ' + numbro(parseFloat(rowData.walletBalance).toFixed(0)).format() : settings.symbol + ' 0' , type:'numeric', editable:'never'},
      { title: languageJson.signup_via_refferal, field: 'signupViaReferral', type:'boolean', editable:'never'},
      { title: languageJson.refferal_id,  field: 'refferalId', editable:'never'}
  ];

  return (
    usersdata.loading? <CircularLoading/>:
    <MaterialTable
      localization={localization}
      title={languageJson.all_riders}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        sorting: true,
        columnsButton: true
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id,newData));
            }, 600);
          }),
        onRowDelete: oldData =>
          isLive?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteUser(oldData.id));
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert('Restricted in Demo App.');
            }, 600);
          })
          , 
      }}
      detailPanel={[
        {
          tooltip: languageJson.show_details,
          render: rowData => {
            return (
              <div className={classes.table}>
               <div className={classes.heading}>
                <div className={classes.headingCell}>{languageJson.ci1_image}</div>
                <div className={classes.headingCell}>{languageJson.ci2_image}</div>
               </div>
               <div className={classes.line}>
                <div className={classes.cell}><img alt={languageJson.ci1_image} src={rowData.ciFront} className={classes.image}/></div>
                <div className={classes.cell}><img alt={languageJson.ci2_image} src={rowData.ciBack} className={classes.image}/></div>
               </div>
              </div>
            )
          },
        }
      ]}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
}
