import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import {useSelector} from "react-redux";
import  languageJson  from "../config/language";
import {tripStatus} from '../config/keys';
import { dateStyle, dateStyleMoment } from '../config/dateStyle';
import {localization} from '../config/materialTable';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

const BookingCancelled = () => {
  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  const columns =  [
      { title: languageJson.booking_date, field: 'tripdate', render: rowData => rowData.tripdate ? new Date(rowData.tripdate).toLocaleString(dateStyleMoment):null, defaultSort:'desc'},
      { title: languageJson.car_type, field: 'carType' },
      { title: languageJson.customer_name,field: 'customer_name'},
      { title: languageJson.pickup_address, field: 'pickupAddress' },
      { title: languageJson.drop_address, field: 'dropAddress' },
      { title: languageJson.assign_driver, field: 'driver_name' },
      { title: languageJson.booking_status, field: 'status', render: rowData => tripStatus[rowData.status] },
      { title: languageJson.reason, field: 'reason'}
      
  ];
  const [data, setData] = useState([]);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  
  useEffect(()=>{
        if(bookinglistdata.bookings){
          setData(bookinglistdata.bookings.filter(bookings => bookings.status === 'CANCELLED' && bookings.reason !== languageJson.reason_cancel_payment));
        }
  },[bookinglistdata.bookings]);
  
  return (
    bookinglistdata.loading? <CircularLoading/>:
    <div>
    <MaterialTable
      localization={localization}
      title={languageJson.bookings_cancelled_payment}
      columns={columns}
      data={data.reverse()}
      options={{
        columnsButton: true,
        grouping: true
      }}
    />
    </div>
  );
}

export default BookingCancelled;
