import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import {useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {localization} from '../config/materialTable';
import {dateStyle} from '../config/dateStyle';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

export default function DriverSummary() {

  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

    const columns =  [
        { title: languageJson.year,field: 'year'},
        { title: languageJson.months, field: 'monthsName' },
        { title: languageJson.driver_name, field: 'driverName'},
        { title: languageJson.vehicle_number, field: 'driverVehicleNo', render: rowData => rowData.driverVehicleNo.toUpperCase() },
        { title: languageJson.booking_alert, field: 'driverReport'}
    ];

  const [data, setData] = useState([]);
  const driversummarydata = useSelector(state => state.driversummarydata);

  useEffect(()=>{
        if(driversummarydata.driversummary){
            setData(driversummarydata.driversummary);
        }
  },[driversummarydata.driversummary]);

  return (
    driversummarydata.loading ? <CircularLoading/>:
    <MaterialTable
      localization={localization}
      title={languageJson.driver_summary}
      columns={columns}
      data={data.reverse()}
      options={{
        exportButton: true,
        grouping: true
      }}
    />
  );
}
