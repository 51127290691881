import { 
    FETCH_DRIVERS_SUMMARY_CANCELLED,
    FETCH_DRIVERS_SUMMARY_CANCELLED_SUCCESS,
    FETCH_DRIVERS_SUMMARY_CANCELLED_FAILED,
  } from "../actions/types";
  
  export const INITIAL_STATE = {
    driversummarycancelled:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_DRIVERS_SUMMARY_CANCELLED:
        return {
          ...state,
          loading:true
        };
      case FETCH_DRIVERS_SUMMARY_CANCELLED_SUCCESS:
        return {
          ...state,
          driversummarycancelled:action.payload,
          loading:false
        };
      case FETCH_DRIVERS_SUMMARY_CANCELLED_FAILED:
        return {
          ...state,
          driversummarycancelled:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
        default:
        return state;
    }
  };