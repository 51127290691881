import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import languageJson from "../config/language";
import { editCarType } from "../actions/cartypeactions";
import {isLive} from '../config/keys';
import {localization} from '../config/materialTable';
import { dateStyle } from '../config/dateStyle';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

export default function CarTypes() {
  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  const [settings,setSettings] = useState({});
  
  const settingsdata = useSelector(state => state.settingsdata);

  useEffect(()=>{
    if(settingsdata.settings){
      setSettings(settingsdata.settings);
    }
  },[settingsdata.settings]);

  const columns = [
    { title: languageJson.image, field: 'image', render: rowData => <img alt='Imus' src={rowData.image} style={{ width: 50 }} /> },
    { title: languageJson.name, field: 'name' },
    { title: languageJson.rate_per_km, field: 'rate_per_kilometer', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.rate_per_kilometer).toFixed(0)).format() , type: 'numeric' },
    { title: languageJson.rate_per_hour, field: 'rate_per_hour', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.rate_per_hour).toFixed(0)).format() ,type: 'numeric' },
    { title: languageJson.min_fare, field: 'min_fare', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.min_fare).toFixed(0)).format() , type: 'numeric' },
    { title: languageJson.rate_special_percent, field: 'rate_special', type: 'numeric' },
    { title: languageJson.convenience_fee_percent, field: 'convenience_fees', type: 'numeric' }
  ];
  const [data, setData] = useState([]);
  const cartypes = useSelector(state => state.cartypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars);
    }
  }, [cartypes.cars]);

  const removeExtraKeys = (tblData) => {
    for (let i = 0; i < tblData.length; i++) {
      if (tblData[i].rate_per_kilometer) tblData[i].rate_per_kilometer = parseFloat(tblData[i].rate_per_kilometer);
      if (tblData[i].rate_per_hour) tblData[i].rate_per_hour = parseFloat(tblData[i].rate_per_hour);
      if (tblData[i].rate_special) tblData[i].rate_special = parseFloat(tblData[i].rate_special);
      if (tblData[i].convenience_fees) tblData[i].convenience_fees = parseFloat(tblData[i].convenience_fees);
    }
    return tblData;
  }

  return (
    cartypes.loading ? <CircularLoading /> :
      <MaterialTable
        localization={localization}
        title={languageJson.car_type}
        columns={columns}
        data={data}
        options={{
          exportButton: true
        }}
        editable={isLive ? {
          onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData.push(newData);
                dispatch(editCarType(removeExtraKeys(tblData), "Add"));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editCarType(removeExtraKeys(tblData), "Update"));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData.splice(tblData.indexOf(oldData), 1);
                dispatch(editCarType(removeExtraKeys(tblData), "Delete"));
              }, 600);
            }),
        } : null}
      />
  );
}
