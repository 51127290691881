import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {localization} from '../config/materialTable';
import { dateStyle } from '../config/dateStyle';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

export default function Earningreports() {

  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  const [settings,setSettings] = useState({});

  const settingsdata = useSelector(state => state.settingsdata);

  useEffect(()=>{
    if(settingsdata.settings){
      setSettings(settingsdata.settings);
    }
  },[settingsdata.settings]);

    const columns =  [
        { title: languageJson.year,field: 'year'},
        { title: languageJson.months, field: 'monthsName' },
        { title: languageJson.Gross_trip_cost, field: 'tripCost', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.tripCost).toFixed(0)).format() },
        { title: languageJson.trip_cost_driver_share, field: 'rideCost', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.rideCost).toFixed(0)).format() },
        { title: languageJson.convenience_fee, field: 'convenienceFee', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.convenienceFee).toFixed(0)).format() },
        { title: languageJson.Discounts, field: 'discountAmount', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.discountAmount).toFixed(0)).format() },
        { title: languageJson.Customer_paid, field: 'customerPaid', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.customerPaid).toFixed(0)).format() },
        { title: languageJson.Profit, field: 'myEarning', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.myEarning).toFixed(0)).format() },
        { title: languageJson.driver_trip, field: 'driverTrip'}
    ];

  const [data, setData] = useState([]);
  const Earningreportsdata = useSelector(state => state.Earningreportsdata);

  useEffect(()=>{
        if(Earningreportsdata.Earningreportss){
            setData(Earningreportsdata.Earningreportss);
        }
  },[Earningreportsdata.Earningreportss]);

  return (
    Earningreportsdata.loading? <CircularLoading/>:
    <MaterialTable
      localization={localization}
      title={languageJson.earning_reports}
      columns={columns}
      data={data.reverse()}
      options={{
        exportButton: true
      }}
    />
  );
}
