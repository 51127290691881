import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {
     addUser, editUser, deleteUser
  }  from "../actions/usersactions";
import {isLive} from '../config/keys';
import {localization} from '../config/materialTable';

export default function Users() {
  const [data, setData] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(usersdata.users){
      if(usersdata.users){
        setData(usersdata.users.filter(user => user.usertype ==='Admin'));
      }else{
      setData([]);
      }
    }
  },[usersdata.users]);

  const columns = [
      { title: languageJson.first_name, field: 'firstName'},
      { title: languageJson.last_name, field: 'lastName'},
      { title: languageJson.user_type, field: 'usertype', editable:'never'},
      { title: languageJson.email, field: 'email'},
  ];

  const removeExtraKeys = (tblData) => {
    return tblData;
  }

  return (
    usersdata.loading? <CircularLoading/>:
    <MaterialTable
      localization={localization}
      title={languageJson.all_admins}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        sorting: true,
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              newData['isAdmin'] = true;
              newData['usertype'] = 'Admin';
              const tblData = data;
              tblData.push(newData);
              dispatch(addUser(removeExtraKeys(newData)));
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id,newData));
            }, 600);
          }),
        onRowDelete: oldData =>
          isLive?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteUser(oldData.id));
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert('Restricted in Demo App.');
            }, 600);
          })
          , 
      }}
    />
  );
}
