import React,{useState} from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem
}from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import logo from '../assets/sidemenu_logo.jpg';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CarIcon from '@material-ui/icons/DirectionsCar';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
import OfferIcon from '@material-ui/icons/LocalOffer';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PeopleIcon from '@material-ui/icons/People';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
//import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ErrorIcon from '@material-ui/icons/Error';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CancelIcon from '@material-ui/icons/Cancel';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
//import InfoIcon from '@material-ui/icons/Info';
import languageJson  from "../config/language";
import {
  signOut
}  from "../actions/authactions";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingRight: theme.spacing(4)
  },
  subtitle: {
    fontSize: '0.8rem'
  }
}));
const arrowLeft = {
  position: 'absolute',
  left: 0
};

function AppMenu() {
  const classes = useStyles();
  const [menuActive, setMenuActive] = useState([false, false, false, false, false, false]);
  const handleClick = (index) => {
    let temp = menuActive;
    temp[index] = !menuActive[index];
    setMenuActive(temp);
  };
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

    return (
    <div>
      <div style={{display:'flex',justifyContent:'center',backgroundColor:'#fff'}}>
        <img style={{width:'120px',height:'120px'}} src={logo} alt="Imus UAC" />
      </div>
      <Divider/>
      <MenuList>
        <MenuItem component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.dashboard_text}</Typography>
        </MenuItem>
        <MenuItem button onClick={() => handleClick(1)} component={Link} to="#">
                 <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
            <Typography variant="inherit">{languageJson.booking_text}</Typography>
            {menuActive[1] ?
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowRightIcon />
              </ListItemIcon>
            }
        </MenuItem>
        <Collapse in={menuActive[1]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/bookings">
                  <ListItemIcon>
                    <FormatListBulletedIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.booking_history}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/bookingsalert">
                  <ListItemIcon>
                    <ErrorIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.booking_alert}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/bookingscancelled">
                  <ListItemIcon>
                    <CancelIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.bookings_cancelled}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/bookingscancelledpayment">
                  <ListItemIcon>
                    <MoneyOffIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.bookings_cancelled_payment}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/addBookings">
                  <ListItemIcon>
                    <ContactPhoneIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.addbookinglable}</Typography>
            </ListItem>
          </List>
        </Collapse>
        <MenuItem button onClick={() => handleClick(2)} component={Link} to="#">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
            <Typography variant="inherit">{languageJson.user}</Typography>
            {menuActive[2] ?
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowRightIcon />
              </ListItemIcon>
            }
        </MenuItem>
        <Collapse in={menuActive[2]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/riders">
                  <ListItemIcon>
                    <EmojiPeopleIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.riders}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/drivers">
                  <ListItemIcon>
                    <AirlineSeatReclineNormalIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.drivers}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/fleetadmins">
                  <ListItemIcon>
                    <GroupAddIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.fleetadmins}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/alladmins">
                  <ListItemIcon>
                    <SupervisorAccountIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.admins}</Typography>
            </ListItem>
          </List>
        </Collapse>
        <MenuItem component={Link} to="/cartypes">
          <ListItemIcon>
            <CarIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.car_type}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/cancelreasons">
          <ListItemIcon>
            <CancelScheduleSendIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.cancellation_reasons}</Typography>
        </MenuItem>
        <MenuItem button onClick={() => handleClick(3)} component={Link} to="#">
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
            <Typography variant="inherit">{languageJson.reports}</Typography>
            {menuActive[3] ?
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowRightIcon />
              </ListItemIcon>
            }
        </MenuItem>
        <Collapse in={menuActive[3]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/earningreports">
                  <ListItemIcon>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.earning_reports}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/driverearning">
                  <ListItemIcon>
                    <MoneyIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.driver_earning}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/driversummary">
                  <ListItemIcon>
                    <ErrorIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.driver_summary}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/driversummarycancelled">
                  <ListItemIcon>
                  <CancelIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.driver_summary_cancelled}</Typography>
            </ListItem>
          </List>
        </Collapse>
        <MenuItem button onClick={() => handleClick(4)} component={Link} to="#">
                <ListItemIcon>
                  <EmojiPeopleIcon />
                </ListItemIcon>
            <Typography variant="inherit">{languageJson.promo_gift}</Typography>
            {menuActive[4] ?
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowRightIcon />
              </ListItemIcon>
            }
        </MenuItem>
        <Collapse in={menuActive[4]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/promos">
                  <ListItemIcon>
                    <OfferIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.promo}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/giftcards">
                  <ListItemIcon>
                    <CardGiftcardIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.gift}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/addgift">
                  <ListItemIcon>
                    <AddBoxIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.addgift}</Typography>
            </ListItem>
          </List>
        </Collapse>
        <MenuItem button onClick={() => handleClick(5)} component={Link} to="#">
                <ListItemIcon>
                <AirlineSeatReclineNormalIcon />
                </ListItemIcon>
            <Typography variant="inherit">{languageJson.promo_gift_driver}</Typography>
            {menuActive[5] ?
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowDownIcon />
              </ListItemIcon>
              :
              <ListItemIcon style={arrowLeft}>
                <KeyboardArrowRightIcon />
              </ListItemIcon>
            }
        </MenuItem>
        <Collapse in={menuActive[5]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/promosdriver">
                  <ListItemIcon>
                    <OfferIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.promo}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/giftcardsdriver">
                  <ListItemIcon>
                    <CardGiftcardIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.gift}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/addgiftdriver">
                  <ListItemIcon>
                    <AddBoxIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.addgift}</Typography>
            </ListItem>
            <ListItem button className={classes.nested} component={Link} to="/vouchersdriver">
                  <ListItemIcon>
                    <CardMembershipIcon />
                  </ListItemIcon>
                <Typography className={classes.subtitle}>{languageJson.voucher_drivers_pending}</Typography>
            </ListItem>
          </List>
        </Collapse>
        {/*<MenuItem component={Link} to="/referral">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.refferal_bonus}</Typography>
        </MenuItem>*/}
        <MenuItem component={Link} to="/notifications">
          <ListItemIcon>
            <NotifyIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.push_notification_title}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/settings">
          <ListItemIcon>
            <LocalAtmIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.settings_title}</Typography>
        </MenuItem>
        {/*<MenuItem component={Link} to='/profile'>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.profile}</Typography>
        </MenuItem>*/}
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;