export default {
    booking_date: 'Fecha de reserva',
    trip_start_time: 'Hora de inicio del viaje',
    trip_end_time: 'Hora de finalización del viaje',
    customer_name: 'Pasajero',
    car_type: 'Tipo de vehículo',
    vehicle_no: 'Chapa',
    pickup_address: 'Dirección de partida',
    drop_address: 'Dirección de destino',
    assign_driver: 'Conductor',
    discount_ammount: 'Importe de descuento',
    payment_mode: 'Modo de pago',
    booking_text: 'Reservas',
    image: 'Imagen',
    name: 'Nombre',
    rate_per_km: 'Tarifa por kilómetro',
    rate_per_hour: 'Tarifa por hora',
    min_fare: 'Tarifa mínima',
    comfort_fee: 'Tarifas de comisión',
    comfort_fee_percent: 'Comisiones (%)',
    convenience_fee_percent: 'Comisiones (%)' ,
    rate_special_percent: 'Tarifa especial (%)',
    convenience_fee: 'Comisiones',
    today_text: 'Hoy',
    Gross_earning: 'Ingresos brutos',
    admin_convenience_fee: 'Nuestras ganancias',
    this_month_text: 'Mes',
    total: 'Total',
    real_time_driver_section_text: 'Conductores en tiempo real',
    allow_location: 'Permitir ubicación para el mapa en tiempo real',
    sign_in: 'Iniciar sesión',
    email_address: 'Dirección de correo electrónico',
    password: 'Contraseña',
    sign_in_error: 'Error de inicio de sesión. Compruebe el correo electrónico y la contraseña',
    device_type: 'Tipo de dispositivo',
    user_type: 'Tipo de usuario',
    title: 'Título',
    body: 'Mensaje',
    push_notification_title: 'Notificaciones push',
    promo_date: 'Fecha',
    promo_name: 'Nombre de la promoción',
    description: 'Descripción',
    promo_type: 'Tipo de promoción',
    promo_discount_value: 'Valor de descuento',
    select_promo_discount_type: 'Seleccionar tipo de promoción',
    confirm_gift: '¿Desea guardar este vale?',
    max_limit: 'Descuento máximo permitido',
    min_limit: 'Valor mínimo de servicio',
    start_date: 'Fecha de inicio',
    end_date: 'Fecha de finalización',
    promo_usage: 'Promociones disponibles',
    promo_used_by: 'Promociones utilizadas',
    promo_user_avail: 'Usuario',
    promo_offer: 'Pasajero: Promos y ofertas',
    promo_offer_driver: 'Conductor: Promos y ofertas',
    gift_offer: 'Vales de descuentos',
    gift_offer_rider: 'Pasajero: Vales de descuentos',
    gift_offer_driver: 'Conductor: Vales de descuentos',
    refferal_bonus: 'Recomendaciones',
    update_failed: 'Actualización fallida',
    first_name: 'Nombres',
    last_name: 'Apellidos',
    email: 'E-mail',
    mobile: 'Celular',
    profile_image: 'Imagen de perfil',
    vehicle_model: 'Modelo de vehículo',
    account_approve: 'Cuenta aprobada',
    driver_active: 'Estado activo del conductor',
    lisence_image: 'Registro',
    lisence_secure: 'Seguro',
    hab_image: 'Habilitación',
    ci1_image: 'Frontal CI',
    ci2_image: 'Posterior CI',
    insp_image: 'Inspección técnica',
    inv_image: 'Facturas',
    cct_image: 'CCT',
    selfie_image: 'Selfie',
    rsocial: 'Razón social',
    ruc: 'RUC',
    vehicle_number: 'Chapa de vehículo',
    wallet_balance: 'Saldo de cuenta',
    signup_via_refferal: 'Registrarse a través de una recomendación',
    refferal_id: 'Id de referencia',
    all_user: 'Todos los usuarios',
    notification_sent: 'notificaciones enviadas',
    notification_sent_failed: 'Error al enviar la notificación',
    no_user_match: 'No hay usuarios coincidentes',
    no_user_matching: 'No se encontraron usuarios que coincidan con su selección',
    dashboard_text: 'Panel de control',
    user: 'Usuarios',
    promo: 'Promociones',
    promo_id: 'Id',
    gift: 'Vales',
    logout: 'Cerrar sesión',
    driver_earning: 'Historial de conductores',
    earning_amount: 'Importe de ganancias',
    driver_name: 'Nombre del conductor',
    driver_image: 'Imagen del conductor',
    months: 'Meses',
    year: 'Años',
    vehicle_type: 'Tipo de vehículo',
    lisence_no: 'Número de licencia',
    booking_discount_earning: 'Descuento de reserva',
    trip_cost_driver_share: 'Pago de conductor',
    Gross_trip_cost: "Costo bruto del viaje",
    Discounts: 'Descuentos',
    Customer_paid: 'Pago de pasajero',
    Profit: 'Beneficio (pagado al conductor - costo del viaje)',
    earning_reports: 'Informes de ganancias',
    cash_payment_amount: 'Importe del pago en efectivo',
    card_payment_amount: 'Importe del pago con tarjeta',
    wallet_payment_amount: 'Importe del pago de billetera',
    payment_getway: 'Pasarela de pago',
    currency_symbol: 'Símbolo de moneda',
    currency_code: 'Código de moneda',
    settings_title: 'Configuración',
    settings_label1: 'Efectivo',
    settings_label2: 'Billetera',
    settings_label3: 'Reservar OTP',
    settings_label4: 'Aprobación del conductor',
    settings_label5: 'Verificación de correo electrónico obligatoria',
    payment_modes_title: 'Modos de pago',
    queue: 'Ocupado',
    createdAt: 'Fecha de creación',
    security_title: 'Configuración de seguridad',
    panic_num: 'Número de marcación de pánico',
    addbookinglable: 'Agregar reserva',
    addgift: 'Agregar vale',
    addgift_rider: 'Pasajero: Agregar vale',
    addgift_driver: 'Conductor: Agregar vale',
    profile: 'Perfil',
    home: 'Inicio',
    about_us: 'Acerca de nosotros',
    booking_history: 'Historial de reservas',
    booking_alert: 'Reservas denunciadas',
    notification_title: 'Notificación de Imus UAC',
    select_reason: 'Seleccionar motivo de cancelación',
    no_cancel_reason: 'No hay motivos de cancelación disponibles',
    ok: 'Aceptar',
    cancel: 'Cancelar',
    select_country: 'Elija un país',
    google_places_error: 'Error de ID de lugar en ubicación',
    not_valid_rider: 'Este inicio de sesión es un usuario válido pero no de pasajero',
    new_booking_msg: 'Tiene una nueva solicitud de reserva',
    no_driver: 'No se encontró ningún conductor',
    no_bookings: 'No hay reservas disponibles',
    booking_cancelled: 'La reserva está cancelada. ID: ',
    no_cars: 'No hay vehículos disponibles',
    no_route: 'No se encontró ninguna ruta',
    firstname: 'Nombres',
    lastname: 'Apellidos',
    phone: 'Número de teléfono',
    otp: 'OTP',
    profile_updated: 'Perfil actualizado.',
    correct_email: 'Por favor ingrese el correo electrónico correctamente',
    submit: 'Enviar',
    booking_title: 'Todas las reservas',
    booking_id: 'ID de reserva',
    booking_status: 'Estado de la reserva',
    trip_cost: 'Costo del viaje',
    payment_status: 'Estado de pago',
    cancel_booking: 'Cancelar reserva',
    select_car: 'Seleccionar tipo de vehículo',
    select_proper: 'Por favor, seleccione correctamente',
    complete_proper: 'Por favor, complete todos los campos',
    must_login: 'Inicie sesión para reservar',
    pickup_location: 'Lugar de partida',
    drop_location: 'Lugar de destino',
    booking_success: 'Reserva exitosa. ID de reserva: ',
    myaccount: 'Mi cuenta',
    login_signup: 'Iniciar sesión / Registrarse',
    book_now: 'Reserva ahora',
    login_success: 'Inicio de sesión exitoso. Necesito completar el registro ',
    login_validate_error: 'El correo electrónico o la contraseña no son correctos',
    otp_validate_error: 'OTP no es válido',
    reset_pass_msg: 'Enlace de restablecimiento de contraseña enviado al correo electrónico',
    signin: 'Iniciar sesión',
    login: 'Iniciar sesión',
    registrarse: 'Registrarse',
    get_otp: 'Obtener OTP',
    verify_otp: 'Verificar OTP',
    complete_registration: 'Registro completo',
    forgot_pass_title: 'Olvidé mi contraseña',
    forgot_pass_description: 'Complete su dirección de correo electrónico y solicite. El enlace se enviará a la bandeja de entrada de su correo electrónico',
    reset_password: 'Restablecer contraseña',
    email_tab: 'Inicio de sesión por correo electrónico',
    phone_tab: 'Acceso telefónico',
    select_user: 'Seleccionar usuario',
    other_settings: 'Otras configuraciones',
    book_later: 'Reservar más tarde',
    book: 'Reservar',
    booking_date_time: 'Hora de reserva',
    past_booking_error: 'El viaje más tarde no está disponible para la fecha pasada o en los próximos 15 minutos',
    booking_taken: 'Se ha realizado la reserva. El conductor se asignará dentro de los 15 minutos antes de la hora de reserva. Su ID de reserva es: ',
    settings_error: 'No se puede recuperar la configuración',
    estimate: 'Tarifa estimada',
    update_success: 'Actualizado',
    drivers: 'Conductores',
    all_drivers: 'Todos los conductores',
    riders: 'Pasajeros',
    all_riders: 'Todos los pasajeros',
    fleetadmins: 'Flotas',
    all_fleetadmins: 'Todas las flotas',
    admins: 'Admins',
    all_admins: 'Todos los admins',
    cancellation_reasons: 'Motivos de cancelación',
    reason: 'Motivo',
    flat: 'Fijo',
    percentage: 'Porcentaje',
    driver_trip: 'Reservas',
    show_details: 'Ver detalles',
    driver_summary: 'Sumario de conductores: Denuncias',
    driver_summary_cancelled: 'Sumario de conductores: Cancelaciones',
    reports: 'Informes',
    promo_gift: 'Pasajero: Beneficios',
    promo_gift_driver: 'Conductor: Beneficios',
    driver: 'Conductor',
    rider: 'Pasajero',
    bookings_cancelled: 'Reservas canceladas',
    bookings_cancelled_payment: 'Reservas de pagos canceladas',
    reason_cancel_payment: 'Cancelación de pago',
    voucher_drivers_pending: 'Conductor: Vouchers Pendientes',
    voucher_drivers_services: 'Servicios del taller unión',
    voucher_drivers_lubrication: 'Sistema de lubricación'
}