export const FirebaseConfig = {
  apiKey: "AIzaSyCz7JjFXaNrQCOsTRpf8r40ouYh57-4MX0",
  authDomain: "uacurbano.firebaseapp.com",
  databaseURL: "https://uacurbano-default-rtdb.firebaseio.com",
  projectId: "uacurbano",
  storageBucket: "uacurbano.appspot.com",
  messagingSenderId: "208432297146",
  appId: "1:208432297146:web:e72c78c146117f9284fe83",
  measurementId: "G-6JTVDLT7ZT"
};

export const tripStatus = {
  NEW: 'NUEVO',
  ACCEPTED: 'ACEPTADO',
  END: 'FIN',
  CANCELLED: 'CANCELADO'
};

export const paymentStatus = {
  PAID: 'PAGADO',
  IN_PROGRESS: 'EN PROGRESO',
  WAITING: 'ESPERA',
  CANCELLED: 'CANCELADO'
};

export const google_map_key = 'AIzaSyAtVuRZvAqghmu3H6KImfU2p2BDPPfl5DA';

export const isLive = true;