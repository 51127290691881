import {bookingRef} from "../config/firebase";
import { 
    FETCH_VOUCHERS_DRIVERS,
    FETCH_VOUCHERS_DRIVERS_SUCCESS,
    FETCH_VOUCHERS_DRIVERS_FAILED
} from "./types";
import  languageJson  from "../config/language";

export const fetchVoucherDrivers = () => dispatch => {
    dispatch({
      type: FETCH_VOUCHERS_DRIVERS,
      payload: null
    });
    bookingRef.on('value', snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        var renderobj = {}
        Object.keys(data).map(i => {
          if(data[i].status === 'END') {
            var v1 = 0
            var v2 = 0
            Object.keys(data).map(j => {
              if(data[i].driver === data[j].driver) {
                v1+=1;
                v2+=1;
                if(v1 === 5) {
                  let uniqueKey = j + '_' + languageJson.voucher_drivers_services;
                  let bdt = new Date(data[i].tripdate);
                  let start = bdt.getDate() + '/' + (bdt.getMonth()+1) + '/' + bdt.getFullYear()
                  let validity = bdt.getDate() + '/' + (bdt.getMonth()+1) + '/' + (bdt.getFullYear()+1) 
                  renderobj[uniqueKey]={};
                  renderobj[uniqueKey]['promo_name'] = data[i].driver_name;
                  renderobj[uniqueKey]['promo_description'] = languageJson.voucher_drivers_services;
                  renderobj[uniqueKey]['promo_discount_type'] = 'percentage';
                  renderobj[uniqueKey]['promo_discount_value'] = '10';
                  renderobj[uniqueKey]['max_promo_discount_value'] = '300000';
                  renderobj[uniqueKey]['promo_start'] = start;
                  renderobj[uniqueKey]['promo_validity'] = validity;
                  renderobj[uniqueKey]['promo_usage_limit'] = '1';
                  renderobj[uniqueKey]['promo_used_by'] = '0';
                  renderobj[uniqueKey]['uniqueKey'] = uniqueKey;
                  v1 = 0; 
                } 
                if(v2 === 10) {
                  let uniqueKey = j + '_' + languageJson.voucher_drivers_lubrication;
                  let bdt = new Date(data[i].tripdate);
                  let start = bdt.getDate() + '/' + (bdt.getMonth()+1) + '/' + bdt.getFullYear()
                  let validity = bdt.getDate() + '/' + (bdt.getMonth()+1) + '/' + (bdt.getFullYear()+1)
                  renderobj[uniqueKey]={};
                  renderobj[uniqueKey]['promo_name'] = data[i].driver_name;
                  renderobj[uniqueKey]['promo_description'] = languageJson.voucher_drivers_lubrication;
                  renderobj[uniqueKey]['promo_discount_type'] = 'percentage';
                  renderobj[uniqueKey]['promo_discount_value'] = '10';
                  renderobj[uniqueKey]['max_promo_discount_value'] = '100000';
                  renderobj[uniqueKey]['promo_start'] = start;
                  renderobj[uniqueKey]['promo_validity'] = validity;
                  renderobj[uniqueKey]['promo_usage_limit'] = '1';
                  renderobj[uniqueKey]['promo_used_by'] = '0';
                  renderobj[uniqueKey]['uniqueKey'] = uniqueKey;
                  v2 = 0; 
                } 
              }                
              return null
            })
          }
          return null
        });
        if(renderobj) {
          const arr = Object.keys(renderobj).map(i => {
           return renderobj[i]
          })
          dispatch({
            type: FETCH_VOUCHERS_DRIVERS_SUCCESS,
            payload: arr
          });
        }
      } else {
        dispatch({
          type: FETCH_VOUCHERS_DRIVERS_FAILED,
          payload: "Sin vouchers"
        });
      }
    });
  };

  export const confirmVoucher = (data) => (dispatch) => {

  }