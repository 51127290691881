import React,{ useEffect } from 'react';
import CircularLoading from "./CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { fetchCarTypes } from "../actions/cartypeactions";
import { fetchBookings } from "../actions/bookinglistactions";
import { fetchPromos } from "../actions/promoactions";
import { fetchGifts } from "../actions/giftactions";
import { fetchVoucherDrivers } from "../actions/voucherdriversactions";
import { fetchDriver } from "../actions/driverearningaction";
import { fetchDriverSummary } from "../actions/driversummaryaction";
import { fetchDriverSummaryCancelled } from "../actions/driversummarycancelledaction";
import { fetchUsers } from "../actions/usersactions";
import { fetchBonus } from "../actions/referralactions";
import { fetchNotifications } from "../actions/notificationactions";
import { fetchEarningreports } from "../actions/earningreportsaction";
import { fetchSettings } from '../actions/settingsactions';
import { fetchCancelReasons } from '../actions/cancelreasonactions';

function AuthLoading(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    useEffect(()=>{
        if(auth.info){
            dispatch(fetchUsers());
            dispatch(fetchBookings());
            dispatch(fetchCarTypes());
            dispatch(fetchPromos());
            dispatch(fetchGifts());
            dispatch(fetchVoucherDrivers());
            dispatch(fetchDriver());
            dispatch(fetchDriverSummary());
            dispatch(fetchDriverSummaryCancelled());
            dispatch(fetchBonus());
            dispatch(fetchNotifications());
            dispatch(fetchEarningreports());
            dispatch(fetchSettings());
            dispatch(fetchCancelReasons());
        }

    },[auth.info,dispatch]);

    return (
        auth.loading? <CircularLoading/>:props.children
    )
}

export default AuthLoading;