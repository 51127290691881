import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {
     editUser, deleteUser
  }  from "../actions/usersactions";
import {isLive} from '../config/keys';
import { dateStyle, dateStyleMoment } from '../config/dateStyle';
import {localization} from '../config/materialTable';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';


export default function Users() {
  const [data, setData] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  const dispatch = useDispatch();

  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  useEffect(()=>{
    if(usersdata.users){
      if(usersdata.users){
        setData(usersdata.users.filter(user => user.usertype ==='driver'));
      }else{
      setData([]);
      }
    }
  },[usersdata.users]);

  const columns = [
      { title: languageJson.createdAt, field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt?new Date(rowData.createdAt).toLocaleString(dateStyleMoment):null},
      { title: languageJson.first_name, field: 'firstName', editable:'never'},
      { title: languageJson.last_name, field: 'lastName', editable:'never'},
      { title: languageJson.email, field: 'email', editable:'never'},
      { title: languageJson.mobile, field: 'mobile', editable:'never'},
      { title: languageJson.profile_image,  field: 'profile_image',render: rowData => rowData.profile_image?<img alt={languageJson.profile_image} src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never'}
  ];

  return (
    usersdata.loading? <CircularLoading/>:
    <MaterialTable
      localization={localization}
      title={languageJson.all_fleetadmins}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        sorting: true,
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id,newData));
            }, 600);
          }),
        onRowDelete: oldData =>
          isLive?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteUser(oldData.id));
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert('Restricted in Demo App.');
            }, 600);
          })
          , 
      }}
    />
  );
}