import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {localization} from '../config/materialTable';
import { dateStyle } from '../config/dateStyle';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

export default function DriverEarning() {

  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  const [settings,setSettings] = useState({});

  const settingsdata = useSelector(state => state.settingsdata);

  useEffect(()=>{
    if(settingsdata.settings){
      setSettings(settingsdata.settings);
    }
  },[settingsdata.settings]);

    const columns =  [
        { title: languageJson.year,field: 'year'},
        { title: languageJson.months, field: 'monthsName' },
        { title: languageJson.driver_name, field: 'driverName'},
        { title: languageJson.vehicle_number, field: 'driverVehicleNo', render: rowData => rowData.driverVehicleNo.toUpperCase() },
        { title: languageJson.earning_amount, field: 'driverShare', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.driverShare).toFixed(0)).format() },
        { title: languageJson.driver_trip, field: 'driverTrip'}
    ];

  const [data, setData] = useState([]);
  const driverearningdata = useSelector(state => state.driverearningdata);

  useEffect(()=>{
        if(driverearningdata.driverearnings){
            setData(driverearningdata.driverearnings);
        }
  },[driverearningdata.driverearnings]);

  return (
    driverearningdata.loading? <CircularLoading/>:
    <MaterialTable
      localization={localization}
      title={languageJson.driver_earning}
      columns={columns}
      data={data.reverse()}
      options={{
        exportButton: true,
        grouping: true
      }}
    />
  );
}
