import {bookingRef} from "../config/firebase";
import { 
    FETCH_BOOKING_DISCOUNT,
    FETCH_BOOKING__DISCOUNT_SUCCESS,
    FETCH_BOOKING__DISCOUNT_FAILED,
} from "./types";

export const fetchEarningreports =  () => dispatch => {
    dispatch({
      type: FETCH_BOOKING_DISCOUNT,
      payload: null
    });
    bookingRef.on("value", snapshot => {
      if (snapshot.val()) {
          const mainArr = snapshot.val();
          var monthsName = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
          var renderobj = {};
          var driverTrip = 1;
           Object.keys(mainArr).map(j => {
            if(mainArr[j].status === 'END' && mainArr[j].discount_amount !== undefined &&  mainArr[j].driver_share !== undefined &&  mainArr[j].customer_paid !== undefined && mainArr[j].convenience_fees !== undefined && mainArr[j].trip_cost !== undefined){
              let bdt = new Date(mainArr[j].tripdate);
              let uniqueKey = bdt.getFullYear()+'_'+bdt.getMonth();
              if(renderobj[uniqueKey]){
                  renderobj[uniqueKey].discountAmount = parseFloat(renderobj[uniqueKey].discountAmount) + parseFloat(mainArr[j].discount_amount);
                  renderobj[uniqueKey].driverShare = parseFloat(renderobj[uniqueKey].driverShare) + parseFloat(mainArr[j].driver_share);
                  renderobj[uniqueKey].customerPaid = parseFloat(renderobj[uniqueKey].customerPaid) + parseFloat(mainArr[j].customer_paid);
                  renderobj[uniqueKey].convenienceFee = parseFloat(renderobj[uniqueKey].convenienceFee) + parseFloat(mainArr[j].convenience_fees);
                  renderobj[uniqueKey].tripCost = parseFloat(renderobj[uniqueKey].tripCost) + parseFloat(mainArr[j].trip_cost);
                  renderobj[uniqueKey].driverTrip += 1;                 
              }else{
                  renderobj[uniqueKey]={};
                  renderobj[uniqueKey]['dated'] = mainArr[j].tripdate;
                  renderobj[uniqueKey]['year'] = bdt.getFullYear();
                  renderobj[uniqueKey]['month'] = bdt.getMonth();
                  renderobj[uniqueKey]['monthsName'] = monthsName[bdt.getMonth()];
                  renderobj[uniqueKey]['discountAmount'] = parseFloat(mainArr[j].discount_amount);
                  renderobj[uniqueKey]['driverShare'] = parseFloat(mainArr[j].driver_share);
                  renderobj[uniqueKey]['customerPaid'] = parseFloat(mainArr[j].customer_paid);
                  renderobj[uniqueKey]['convenienceFee'] = parseFloat(mainArr[j].convenience_fees); 
                  renderobj[uniqueKey]['uniqueKey'] = uniqueKey; 
                  renderobj[uniqueKey]['tripCost'] = parseFloat(mainArr[j].trip_cost); 
                  renderobj[uniqueKey]['driverTrip'] = driverTrip;
              }
            }
            return null;
          });
          if(renderobj){
            const arr = Object.keys(renderobj).map(i => {
              renderobj[i].myEarning = parseFloat(renderobj[i].customerPaid - renderobj[i].driverShare).toFixed(0);
              renderobj[i].customerPaid = parseFloat(renderobj[i].customerPaid).toFixed(0);
              renderobj[i].rideCost =  parseFloat(renderobj[i].tripCost - renderobj[i].convenienceFee).toFixed(0);
              renderobj[i].driverShare = parseFloat(renderobj[i].driverShare).toFixed(0);
              return renderobj[i]
            })
            dispatch({
              type: FETCH_BOOKING__DISCOUNT_SUCCESS,
              payload: arr
            });
          }
          
      } else {
        dispatch({
          type: FETCH_BOOKING__DISCOUNT_FAILED,
          payload: "No data available."
        });
      }
    });
  };

  