import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import {useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {localization} from '../config/materialTable';

export default function CancellationReasons() {

  const [data, setData] = useState([]);
  const cancelreasondata = useSelector(state => state.cancelreasondata);
  
  useEffect(() => {
    if (cancelreasondata.reasons) {
      setData(cancelreasondata.reasons);
    } else {
      setData([]);
    }
  }, [cancelreasondata.reasons]);

  const columns = [
      { title: languageJson.reason, field: 'label'},
  ];

  return (
    cancelreasondata.loading ? <CircularLoading/> :
    <MaterialTable
      localization={localization}
      title={languageJson.cancellation_reasons}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        sorting: true,
      }}
    />
  );
}