import { 
    FETCH_GIFTS,
    FETCH_GIFTS_SUCCESS,
    FETCH_GIFTS_FAILED,
    CONFIRM_GIFT,
    CONFIRM_GIFT_SUCCESS,
    CONFIRM_GIFT_FAILED,
    CLEAR_GIFT,
    EDIT_GIFTS
  } from "../actions/types";
  
  export const INITIAL_STATE = {
    gifts:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_GIFTS:
        return {
          ...state,
          loading:true
        };
      case FETCH_GIFTS_SUCCESS:
        return {
          ...state,
          gifts:action.payload,
          loading:false
        };
      case FETCH_GIFTS_FAILED:
        return {
          ...state,
          gifts:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case CONFIRM_GIFT:
          return {
            ...state,
            loading:true
          };
      case CONFIRM_GIFT_SUCCESS:
          return {
            ...state,
            gift:action.payload,
            loading:false
          };
      case CONFIRM_GIFT_FAILED:
          return {
            ...state,
            gift:null,
            loading:false,
            error:{
              flag:true,
              msg:action.payload
            }
          };
      case CLEAR_GIFT:
          return INITIAL_STATE;
      case EDIT_GIFTS:
        return state;
      default:
        return state;
    }
  };