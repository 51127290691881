import {bookingRef} from "../config/firebase";
import { 
    FETCH_DRIVERS_SUMMARY_CANCELLED,
    FETCH_DRIVERS_SUMMARY_CANCELLED_SUCCESS,
    FETCH_DRIVERS_SUMMARY_CANCELLED_FAILED,
} from "./types";
import languageJson  from "../config/language";

export const fetchDriverSummaryCancelled =  () => dispatch => {
    dispatch({
      type: FETCH_DRIVERS_SUMMARY_CANCELLED,
      payload: null
    });
    bookingRef.on("value", snapshot => {
      if (snapshot.val()) {
          const mainArr = snapshot.val();
          var monthsName = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
          var renderobj = {};
          var driverCancelled = 1;
          Object.keys(mainArr).map(j => {
            if(mainArr[j].status === 'CANCELLED' && mainArr[j].reason !== languageJson.reason_cancel_payment){
              let bdt = new Date(mainArr[j].tripdate);
              let uniqueKey = bdt.getFullYear()+'_'+bdt.getMonth()+'_'+mainArr[j].driver;
              if(renderobj[uniqueKey] && mainArr[j].status === 'CANCELLED'){
                  renderobj[uniqueKey].driverCancelled += 1;
              }else{
                  renderobj[uniqueKey]={};
                  renderobj[uniqueKey]['year'] = bdt.getFullYear();
                  renderobj[uniqueKey]['month'] = bdt.getMonth();
                  renderobj[uniqueKey]['monthsName'] = monthsName[bdt.getMonth()];
                  renderobj[uniqueKey]['driverName'] = mainArr[j].driver_name;
                  renderobj[uniqueKey]['driverVehicleNo'] = mainArr[j].vehicle_number;
                  renderobj[uniqueKey]['driverUId'] = mainArr[j].driver;
                  renderobj[uniqueKey]['driverCancelled'] = driverCancelled;
                  renderobj[uniqueKey]['uniqueKey'] = uniqueKey; 
              }
            }
            return null;
          });
          if(renderobj){
            const arr = Object.keys(renderobj).map(i => {
             return renderobj[i]
            })
            dispatch({
              type: FETCH_DRIVERS_SUMMARY_CANCELLED_SUCCESS,
              payload: arr
            });
          }
      } else {
        dispatch({
          type: FETCH_DRIVERS_SUMMARY_CANCELLED_FAILED,
          payload: "Sin datos"
        });
      }
    });
  };

  