export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

export const FETCH_CAR_TYPES = "FETCH_CAR_TYPES";
export const FETCH_CAR_TYPES_SUCCESS = "FETCH_CAR_TYPES_SUCCESS";
export const FETCH_CAR_TYPES_FAILED = "FETCH_CAR_TYPES_FAILED";
export const EDIT_CAR_TYPE = "EDIT_CAR_TYPE";

export const FETCH_BOOKINGS= "FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILED = "FETCH_BOOKINGS_FAILED";
export const CANCEL_BOOKING= "CANCEL_BOOKING";

export const FETCH_ESTIMATE= "FETCH_ESTIMATE";
export const FETCH_ESTIMATE_SUCCESS= "FETCH_ESTIMATE_SUCCESS";
export const FETCH_ESTIMATE_FAILED= "FETCH_ESTIMATE_FAILED";
export const CLEAR_ESTIMATE= "CLEAR_ESTIMATE";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_FAILED = "CONFIRM_BOOKING_FAILED";
export const CLEAR_BOOKING = "CLEAR_BOOKING";

export const FETCH_CANCEL_REASONS = "FETCH_CANCEL_REASONS";
export const FETCH_CANCEL_REASONS_SUCCESS = "FETCH_CANCEL_REASONS_SUCCESS";
export const FETCH_CANCEL_REASONS_FAILED = "FETCH_CANCEL_REASONS_FAILED";

export const FETCH_PROMOS= "FETCH_PROMOS";
export const FETCH_PROMOS_SUCCESS = "FETCH_PROMOS_SUCCESS";
export const FETCH_PROMOS_FAILED = "FETCH_PROMOS_FAILED";
export const EDIT_PROMOS = "EDIT_PROMOS";

export const FETCH_GIFTS= "FETCH_GIFTS";
export const FETCH_GIFTS_SUCCESS = "FETCH_GIFTS_SUCCESS";
export const FETCH_GIFTS_FAILED = "FETCH_GIFTS_FAILED";
export const EDIT_GIFTS = "EDIT_GIFTS";

export const CONFIRM_GIFT = "CONFIRM_GIFT";
export const CONFIRM_GIFT_SUCCESS = "CONFIRM_GIFT_SUCCESS";
export const CONFIRM_GIFT_FAILED = "CONFIRM_GIFT_FAILED";
export const CLEAR_GIFT = "CLEAR_GIFT";

export const FETCH_ALL_USERS= "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const FETCH_REFERRAL_BONUS= "FETCH_REFERRAL_BONUS";
export const FETCH_REFERRAL_BONUS_SUCCESS = "FETCH_REFERRAL_BONUS_SUCCESS";
export const FETCH_REFERRAL_BONUS_FAILED = "FETCH_REFERRAL_BONUS_FAILED";
export const EDIT_REFERRAL_BONUS = "EDIT_REFERRAL_BONUS";
export const CLEAR_REFERRAL_ERROR = "CLEAR_REFERRAL_ERROR";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const EDIT_NOTIFICATIONS = "EDIT_NOTIFICATIONS";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const FETCH_DRIVERS_EARNING = "FETCH_DRIVERS_EARNING";
export const FETCH_DRIVERS__EARNING_SUCCESS = "FETCH_DRIVERS__EARNING_SUCCESS";
export const FETCH_DRIVERS__EARNING_FAILED = "FETCH_DRIVERS__EARNING_FAILED";

export const FETCH_DRIVERS_SUMMARY = "FETCH_DRIVERS_SUMMARY";
export const FETCH_DRIVERS_SUMMARY_SUCCESS = "FETCH_DRIVERS_SUMMARY_SUCCESS";
export const FETCH_DRIVERS_SUMMARY_FAILED = "FETCH_DRIVERS_SUMMARY_FAILED";

export const FETCH_DRIVERS_SUMMARY_CANCELLED = "FETCH_DRIVERS_CANCELLED";
export const FETCH_DRIVERS_SUMMARY_CANCELLED_SUCCESS = "FETCH_DRIVERS_SUMMARY_CANCELLED_SUCCESS";
export const FETCH_DRIVERS_SUMMARY_CANCELLED_FAILED = "FETCH_DRIVERS_SUMMARY_CANCELLED_FAILED";

export const FETCH_VOUCHERS_DRIVERS = "VOUCHERS_DRIVERS";
export const FETCH_VOUCHERS_DRIVERS_SUCCESS = "VOUCHERS_DRIVERS_SUCCESS";
export const FETCH_VOUCHERS_DRIVERS_FAILED = "FETCH_VOUCHERS_DRIVERS_FAILED";
export const EDIT_VOUCHERS_DRIVERS = "EDIT_VOUCHERS_DRIVERS"

export const FETCH_BOOKING_DISCOUNT = "FETCH_BOOKING_DISCOUNT";
export const FETCH_BOOKING__DISCOUNT_SUCCESS = "FETCH_BOOKING__DISCOUNT_SUCCESS";
export const FETCH_BOOKING__DISCOUNT_FAILED = "FETCH_BOOKING__DISCOUNT_FAILED";
export const EDIT_BOOKING__DISCOUNT = "EDIT_BOOKING__DISCOUNT";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILED = "FETCH_SETTINGS_FAILED";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const CLEAR_SETTINGS_ERROR = "CLEAR_SETTINGS_ERROR";