import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import { editPromo }  from "../actions/promoactions";
import {isLive} from '../config/keys';
import {localization} from '../config/materialTable';
import { dateStyle } from '../config/dateStyle';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

export default function Promos() {
  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  const [settings,setSettings] = useState({});

  const settingsdata = useSelector(state => state.settingsdata);

  useEffect(()=>{
    if(settingsdata.settings){
      setSettings(settingsdata.settings);
    }
  },[settingsdata.settings]);

    const columns =  [
      { title: languageJson.promo_date, field: 'createdAt', defaultSort:'desc', render: rowData => rowData.createdAt ? new Date(rowData.createdAt).toLocaleString(dateStyle):null, editable: 'never'},
        { title: languageJson.promo_name, field: 'promo_name'},
        { title: languageJson.description, field: 'promo_description' },
        {
            title: languageJson.promo_type,
            field: 'promo_discount_type',
            lookup: { flat: languageJson.flat, percentage: languageJson.percentage },
        },
        { title: languageJson.promo_discount_value, field: 'promo_discount_value', render: rowData => numbro(parseFloat(rowData.promo_discount_value).toFixed(0)).format() ,type: 'numeric'},
        { title: languageJson.max_limit, field: 'max_promo_discount_value', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.max_promo_discount_value).toFixed(0)).format(), type: 'numeric' },
        { title: languageJson.min_limit, field: 'min_order' , render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.min_order).toFixed(0)).format(), type: 'numeric'},
        { title: languageJson.start_date, field: 'promo_start'},
        { title: languageJson.end_date, field: 'promo_validity'},
        { title: languageJson.promo_usage, field: 'promo_usage_limit', type: 'numeric' },
        { title: languageJson.promo_used_by, field: 'promo_used_by', render: rowData => (rowData.promo_used_by > 0) ? rowData.promo_used_by:0, editable:'never' }
    ];

  const [data, setData] = useState([]);
  const promodata = useSelector(state => state.promodata);
  const dispatch = useDispatch();

  useEffect(()=>{
        if(promodata.promos){
          setData(promodata.promos.filter(promo => promo.usertype ==='rider'));
        }
  },[promodata.promos]);

  const removeExtraKeys = (tblData) => {
        if(tblData.promo_discount_value) tblData.promo_discount_value = parseFloat(tblData.promo_discount_value);
        if(tblData.max_promo_discount_value) tblData.max_promo_discount_value = parseFloat(tblData.max_promo_discount_value);
        if(tblData.min_order) tblData.min_order = parseFloat(tblData.min_order);
        if(tblData.promo_usage_limit) tblData.promo_usage_limit = parseFloat(tblData.promo_usage_limit);
    return tblData;
  }

  return (
    promodata.loading? <CircularLoading/>:
    <MaterialTable
      localization={localization}
      title={languageJson.promo_offer}
      columns={columns}
      data={data.reverse()}
      options={{
        columnsButton: true
      }}
      editable={isLive?{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              newData['createdAt'] = new Date().toISOString();
              newData['promo_used_by'] = 0;
              newData['usertype'] = 'rider';
              newData['user_avail'] = '';
              const tblData = data;
              tblData.push(newData);
              dispatch(editPromo(removeExtraKeys(newData),"Add"));
            }, 600);
          }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editPromo(removeExtraKeys(newData),"Update"));
              }, 600);
            }),
          onRowDelete: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editPromo(removeExtraKeys(newData),"Delete"));
              }, 600);
          }), 
      }:null} 
    />
  );
}
