import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import { confirmVoucher }  from "../actions/voucherdriversactions";
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import {isLive} from '../config/keys';
import {localization} from '../config/materialTable';
import { dateStyle } from '../config/dateStyle';
import numbro from 'numbro';
import numbroLanguages from 'numbro/dist/languages.min';

export default function VouchersDriver() {
  numbro.registerLanguage(numbroLanguages[dateStyle]);
  numbro.setLanguage(dateStyle);
  numbro.setDefaults({thousandSeparated: true});

  const [settings,setSettings] = useState({});

  const settingsdata = useSelector(state => state.settingsdata);

  useEffect(()=>{
    if(settingsdata.settings){
      setSettings(settingsdata.settings);
    }
  },[settingsdata.settings]);

    const columns =  [
        { title: languageJson.promo_name, field: 'promo_name'},
        { title: languageJson.description, field: 'promo_description' },
        {
            title: languageJson.promo_type,
            field: 'promo_discount_type',
            lookup: { flat: languageJson.flat, percentage: languageJson.percentage },
        },
        { title: languageJson.promo_discount_value, field: 'promo_discount_value', render: rowData => numbro(parseFloat(rowData.promo_discount_value).toFixed(0)).format() ,type: 'numeric'},
        { title: languageJson.max_limit, field: 'max_promo_discount_value', render: rowData => settings.symbol + ' ' + numbro(parseFloat(rowData.max_promo_discount_value).toFixed(0)).format(), type: 'numeric' },
        { title: languageJson.start_date, field: 'promo_start'},
        { title: languageJson.end_date, field: 'promo_validity'},
        { title: languageJson.promo_usage, field: 'promo_usage_limit', type: 'numeric' },
        { title: languageJson.promo_used_by, field: 'promo_used_by', render: rowData => (rowData.promo_used_by > 0) ? rowData.promo_used_by:0, editable:'never' }
    ];

  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState('');
  const voucherdriversdata = useSelector(state => state.voucherdriversdata);
  
  useEffect(()=>{
        if(voucherdriversdata.voucherdrivers){
          setData(voucherdriversdata.voucherdrivers);
        }
  },[voucherdriversdata.voucherdrivers]);

  const dispatch = useDispatch();

  const onConfirmClose=(value)=>{
    if(value){
      dispatch(confirmVoucher({
        reason:value,
        booking:selectedVoucher
      }));
    }
    setOpenConfirm(false);
  }

  return (
    voucherdriversdata.loading? <CircularLoading/>:
    <div>
    <MaterialTable
      localization={localization}
      title={languageJson.voucher_drivers_pending}
      columns={columns}
      data={data.reverse()}
      options={{
        actionsColumnIndex: -1,
        columnsButton: true,
        grouping: true
      }}
      actions={[
        rowData => ({
          icon: 'check',
          tooltip: languageJson.cancel_booking,
          onClick: (event, rowData) => {
            if(isLive){
              setSelectedVoucher(rowData);
              setOpenConfirm(true);
            }else{
              alert('Restricted in Demo App.');
            }
          }         
        }),
      ]}
    />
    <ConfirmationDialogRaw
      open={openConfirm}
      onClose={onConfirmClose}
      value={''}
    />
    </div>
  );
}
